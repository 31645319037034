import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { site, markdownRemark } = data // data.markdownRemark holds your post data
    const { siteMetadata } = site
    const { frontmatter, html } = markdownRemark
    return (
        <Layout>
            <Helmet>
                <title>{frontmatter.title} | {siteMetadata.title}</title>
                <meta name="description" content={frontmatter.metaDescription} />
            </Helmet>

            <div className="block blog-post font-article bg-white shadow-lg rounded-2xl p-20 max-w-5xl mx-auto">
                <div className="flex flex-col py-2">
                    <p className="flex flex-row justify-center text-center text-5xl text-gray-800 font-bold py-2">{frontmatter.title}</p>
                    <p className="flex flex-row justify-center text-sm md:text-base font-normal text-gray-400 pt-5">Published {frontmatter.date}</p>
                </div>
                <div className="flex flex-row justify-center py-5 pb-20">
                    <img className="h-4/5 w-4/5 object-contain md:object-scale-down shadow-xl" src={frontmatter.thumbnail} />
                </div>
                <div
                    className="flex flex-col prose lg:prose-xl font-body space-y-5 xs:max-w-xs sm:max-w-max"
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </div>
        </Layout >
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
  }
`